import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

mapboxgl.accessToken = 'pk.eyJ1IjoibWRvcm5zZWlmIiwiYSI6ImNpZjdua2Z0NzAwMWR1OGtvMTJkbHU3dzUifQ.XCEZIIjx6PSBQdlRiw0HYA'

export function initMap (mapContainer, onMapReady) {
  let map = new mapboxgl.Map({
    container: mapContainer,
    style: 'mapbox://styles/mapbox/light-v10',
    // monument hospital attraction campsite  embassy information  mountain  parking rocket parking viewpoint     "border-dot-13"    "dot-10"    "dot-11"    "dot-9"
    // icons unter
    // https://api.mapbox.com/styles/v1/mapbox/light-v10/sprite@2x.json?access_token=pk.eyJ1IjoibWRvcm5zZWlmIiwiYSI6ImNpZjdua2Z0NzAwMWR1OGtvMTJkbHU3dzUifQ.XCEZIIjx6PSBQdlRiw0HYA
    // https://api.mapbox.com/styles/v1/mapbox/light-v10/sprite@2x.png?access_token=pk.eyJ1IjoibWRvcm5zZWlmIiwiYSI6ImNpZjdua2Z0NzAwMWR1OGtvMTJkbHU3dzUifQ.XCEZIIjx6PSBQdlRiw0HYA
    // mapbox://styles/mapbox/streets-v11
    // mapbox://styles/mapbox/outdoors-v11
    // mapbox://styles/mapbox/light-v10
    // mapbox://styles/mapbox/satellite-v9
    // mapbox://styles/mapbox/satellite-streets-v11
    // https://api.mapbox.com/styles/v1/tempgeocent/cj2qe6qid003a2rmrquvqgbcx.html
    // https://www.mapbox.com/designer-maps/
    // https://www.mapbox.com/gallery/#
    // emerald-v8
    center: [6.9426802, 50.9382059], // Köln
    zoom: 4
  })
  // Add zoom and rotation controls to the map.
  map.addControl(new mapboxgl.NavigationControl())
  map.addControl(new mapboxgl.ScaleControl())
  map.addControl(new mapboxgl.FullscreenControl())
  map.addControl(new MoreInfoControl(e => this.setState({ detailsRequested: true })), 'bottom-right')
  map.scrollZoom.disable() // avoind snatching the scrollwheel from the page
  // more elegant solution: https://github.com/Leaflet/Leaflet/issues/2076#issuecomment-41112469

  map.on('load', onMapReady)
  return map
}

class MoreInfoControl {
  // Info-Button in der Karte
  constructor (onClick) {
    this.onClick = onClick
  }

  onAdd (map) {
    this._map = map
    this._container = document.createElement('div')
    this._container.className = 'mapboxgl-ctrl'
    this._container.textContent = 'Info'
    this._container.addEventListener('click', (e) => {
      this._container.style.display = 'none'
      this.onClick(e)
      e.stopPropagation()
    }, false)
    return this._container
  }

  onRemove () {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }
}
