import React from 'react'
import axios from 'axios'
import { Link, StaticQuery, graphql } from 'gatsby'
import moment from 'moment'

import { Tags } from './sidebar-tagcloud.jsx'

export class Sidebar extends React.Component {
  render () {
    return (
      <aside>
        <LastChanged />
        <Tags />
        <LinkBlog />
      </aside>
    )
  }
}

class LastChanged extends React.Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
  query {
  allMarkdownRemark(filter: {frontmatter: {draft: {ne: true}, modified: {ne: null}}}, sort: {fields: [frontmatter___modified, frontmatter___date], order: DESC}, limit: 15) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          modified
          draft
        }
      }
    }
  }
}
    `}
        render={data =>
          <section className='latest-articles'>
            <h2>letzte Änderungen</h2>
            <div>
              {data.allMarkdownRemark.edges.map((edge) =>
                <LastChangedItem key={edge.node.fields.slug} node={edge.node} />
              )}
            </div>
          </section>
        }
      />)
  }
}

class LastChangedItem extends React.Component {
  render () {
    if (!this.props.node || this.props.node.frontmatter.draft === true) {
      return null
    }
    return (<li key={this.props.node.fields.slug}>
      <Link to={this.props.node.fields.slug}>
        {this.props.node.frontmatter.title}
      </Link>
    </li>)
  }
}

class LinkBlog extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      items: undefined
    }
  }

  componentDidMount () {
    axios.get(`https://micro.blog/posts/md`)
      .then((response) =>
        this.setState({ items: response.data.items })
      )
      .catch(console.log.bind(console))
  }

  render () {
    if (this.state.items === undefined) {
      return null
    } else {
      return (
        <section className='microblog latest-articles'>
          <h2>Linkblog</h2>
          <div>
            <ul>
              {this.state.items.map((item) =>
                <li key={item.url} className='h-entry' data-id={`microblog${item.id}`}>
                  <section>
                    <time className='dt-published articlemeta' >
                      {moment.utc(item.date_published, 'YYYY-MM-DDTHH:mm:ssZ', true).locale('de-DE').format('YYYY-MM-DD')}
                    </time>
                    <a className='u-url' href={item.url}>
                      <div className='e-content' dangerouslySetInnerHTML={{ __html: item.content_html }} />
                    </a>
                  </section>
                </li>
              )}
            </ul>
          </div>
        </section>
      )
    }
  }
}
