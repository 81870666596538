import React from 'react'
import { Link } from 'gatsby'

import { PostCard } from '../components/post-card.jsx'

export class PostSnippet extends React.Component {
  render () {
    const node = this.props.node
    // <SEO title={post.frontmatter.title} description={post.excerpt} />

    return (
      <article className='h-entry post-card' id='articlebox-id'>
        <PostCard node={node}>
          <section className='excerpt'>
            <p>
              <span dangerouslySetInnerHTML={{ __html: node.excerpt }} />
              [<Link className='read-more' to={node.fields.slug}>mehr</Link>]
            </p>
          </section>
        </PostCard>
      </article>
    )
  }
}
