import React from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'
import { TagCloud } from 'react-tagcloud'

export class Tags extends React.Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
          query TagCloudQuery {
            allMarkdownRemark(
              limit: 5000
              filter: { frontmatter: { draft: { ne: true } } }
            ) {
              group(field: frontmatter___tags) {
                fieldValue
                totalCount
              }
            }
          }
        `}
        render={renderCloud}
      />)
  }
}

function renderCloud (data) {
  const group = data.allMarkdownRemark.group
  const tagData = group.map(tag => ({ value: tag.fieldValue, count: tag.totalCount }))

  return (
    <section className='tags'>
      <h2>Schlagworte</h2>
      <div className='sidebox-content'>
        <TagCloud minSize={12}
          maxSize={42}
          tags={tagData}
          onClick={tag => navigate(`/tag/${tag.value}`)} />
      </div>
    </section>
  )
}
