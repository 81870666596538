import React from 'react'

import { Sidebar } from './sidebar.jsx'
import { Header } from './header.jsx'

export class Layout extends React.Component {
  render () {
    const { children } = this.props

    return (
      <div className='dhuu-container'>
        <Header {...this.props} />
        <main>{children}</main>
        <Sidebar />

        <footer>
           © {new Date().getFullYear()}, Built with
          {` `}
          <a href='https://www.gatsbyjs.org'>Gatsby</a>
        </footer>
      </div>
    )
  }
}

export class LayoutWithoutSidebar extends React.Component {
  render () {
    const { children } = this.props

    return (
      <div className='dhuu-container'>
        <Header {...this.props} />
        <main className='nosidebar'>{children}</main>

        <footer>
           © {new Date().getFullYear()}, Built with
          {` `}
          <a href='https://www.gatsbyjs.org'>Gatsby</a>
        </footer>
      </div>
    )
  }
}
