// MAP functionality
//
// Created 2017-11-11

import React from "react"

import mapboxgl from 'mapbox-gl'
// import parseDMS from 'parse-dms'
import geojsonExtent from '@mapbox/geojson-extent'
import produce from 'immer'

import { geoToPlaceName, withStatistics } from '../lib/geo/info.js'
import { loadGpx } from '../lib/geo/loader.js'
import { initMap } from '../lib/geo/map.js'

import 'mapbox-gl/dist/mapbox-gl.css'
const _ = require('lodash')

mapboxgl.accessToken = 'pk.eyJ1IjoibWRvcm5zZWlmIiwiYSI6ImNpZjdua2Z0NzAwMWR1OGtvMTJkbHU3dzUifQ.XCEZIIjx6PSBQdlRiw0HYA'

export class Map extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // mapbox is patched during build time so wait until runtime to initialize
      bounds: undefined,
      detailsRequested: false,
      trackInfo: {},
      trackGeoJson: {}
    }
  }

  componentDidMount() {
    this.map = initMap(this.mapContainer)
    this.map.on('load', () => {
      this.addLocations(this.props.locations)
      if (!_.isEmpty(this.props.tracks)) {
        this.addTracks(this.props.tracks)
      }
    })
  }

  componentWillUnmount() {
    this.map.remove()
  }

  addLocations = (locations) => {
    // location ist ein list von frontmatter Objekten
    const geojson = locationsToGeoJson(locations)
    const extend = geojsonExtent(geojson)
    if(extend) {
      this.setState(produce(draft => {
        if (draft.bounds === undefined) {
          draft.bounds = [[extend[0], extend[1]], [extend[2], extend[3]]]
        } else {
          let locbounds = new mapboxgl.LngLatBounds(draft.bounds)
          locbounds.extend([extend[0], extend[1]], [extend[2], extend[3]])
          draft.bounds = locbounds.toArray()
        }
      }),
        // to be executed after setState
        () => this.map.fitBounds(this.state.bounds, { padding: 30 })
      )
    }
    // siehe https://labs.mapbox.com/maki-icons/ embassy information hospital marker-stroked
    // see https://github.com/mapbox/mapbox-gl-styles#standard-icons
    this.map.addSource('locations-source', { 'type': 'geojson', 'data': geojson })
    this.map.addLayer({
      'id': 'locations',
      'type': 'symbol',
      'source': 'locations-source',
      'layout': {
        'icon-image': '{marker-symbol}-15',
        'text-field': '{name}',
        // "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        'text-offset': [0, 0.6],
        'text-anchor': 'top',
        "icon-allow-overlap": true
      },
    })
  }

  addTracks = async (tracks) => {
    let trackurls = tracks.map(x => x.trackUrl)
    let firstMarker, lastMarker
    trackurls.sort()  // to ensure they are sorted by name/age

    // Start loading all tracks in paralell
    let promiseArray = trackurls.map(async trackurl => withStatistics(await loadGpx(trackurl)))

    // draw them - in order
    const trackcolors = ['#FFD00D', '#ff920d']
    let colorindex = 0
    for (let p of promiseArray) {
      let geojson = await p
      // gejson malen
      this.addTrack(geojson, trackcolors[colorindex++ % trackcolors.length]);
    }

    // add names of start and endpoints
    for (let p2 of promiseArray) {
      const geojson = await p2
      const startName = await geoToPlaceName(geojson.features[0].properties.startCoords)
      const endName = await geoToPlaceName(geojson.features[0].properties.endCoords, true)
      this.setState(produce(draft => {
        draft.trackGeoJson[geojson.features[0].properties.url].features[0].properties.startName = startName
        draft.trackGeoJson[geojson.features[0].properties.url].features[0].properties.endName = endName
      }))

      if (geojson.features[0].properties.coordTimes) {
        firstMarker = firstMarker || geojson.features[0].properties.startCoords
        lastMarker = geojson.features[0].properties.endCoords
      }
    }
    this.drawStartAndEnd(firstMarker, lastMarker)
  }

  addTrack(geojson, color) {
    this.setState(produce(draft => {
      draft.trackGeoJson[geojson.features[0].properties.url] = { ...draft.trackGeoJson[geojson.features[0].properties.url], ...geojson };
    }));
    // bounds setzen
    let extend = geojsonExtent(geojson);
    this.setState(produce(draft => {
      if (draft.bounds === undefined) {
        draft.bounds = [[extend[0], extend[1]], [extend[2], extend[3]]];
      }
      else {
        let locbounds = new mapboxgl.LngLatBounds(draft.bounds);
        console.log('vorher', locbounds.toArray())
        locbounds.extend([extend[0], extend[1]], [extend[2], extend[3]]);
        draft.bounds = locbounds.toArray();
        console.log('nachher', draft.bounds)
      }
    }),
      // to be executed after setState
      () => {console.log("zooming", this.state.bounds) ; this.map.fitBounds(this.state.bounds, { padding: 30 })}
    )

    this.map.addSource(
      `article-track-source-${geojson.features[0].properties.url}`,
      {
        'type': 'geojson',
        'data': geojson
      }
    )
    // die tracks
    this.map.addLayer({
      'id': `article-track-${geojson.features[0].properties.url}`,
      'type': 'line',
      'source': `article-track-source-${geojson.features[0].properties.url}`,
      // see https://www.mapbox.com/mapbox-gl-js/style-spec/#layers-line
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-width': 3,
        'line-color': color
      }
    }, 'locations')

    // die stops
    if(geojson.features.length > 1) {
      this.map.addLayer({
        'id': `article-stops-${geojson.features[0].properties.url}`,
        'type': 'symbol',
        'source': `article-track-source-${geojson.features[0].properties.url}`,
        'layout': {
          'text-field': 'P',
        },
      })
    }
  }

  drawStartAndEnd = (firstMarker, lastMarker) => {
    if(!(firstMarker && lastMarker)) {
      return
    }
    // mark Start and End points
    this.map.addSource('article-track-markers-source', {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': [{
          'type': 'Feature',
          'geometry': { 'type': 'Point', 'coordinates': firstMarker },
          'properties': { 'title': 'Start', 'marker-symbol': 'marker' }
        }, {
          'type': 'Feature',
          'geometry': { 'type': 'Point', 'coordinates': lastMarker },
          'properties': { 'title': 'End', 'marker-symbol': 'campsite' }
        }]
      }
    })
    this.map.addLayer({
      'id': 'article-track-markers',
      'type': 'symbol',
      'source': 'article-track-markers-source',
      'layout': {
        // see https://github.com/mapbox/mapbox-gl-styles#standard-icons
        'icon-image': '{marker-symbol}-15',
        'text-field': '{title}',
        // "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        'text-offset': [0, 0.6],
        'text-anchor': 'top'
      },
    })
  }

  render() {
    return (
      <div name="map">
        <div className='mapholder'>
          <div style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '100%',
            height: '100%'
          }} ref={el => this.mapContainer = el} />
        </div>
        {this.state.detailsRequested ? <TrackInfoTable trackGeoJson={this.state.trackGeoJson} /> : null}
      </div>
    )
  }
}


function locationsToGeoJson(locations) {
  // location ist ein list von frontmatter Objekten
  locations = locations.map(x => ({ ...x, coords: x.gps.split(',').map(x => Number(_.trim(x))) })).map(x => ({ ...x, coords: [x.coords[1], x.coords[0]] }))
  return {
    'type': 'FeatureCollection',
    'features': locations.map(x => ({
      'type': 'Feature',
      'geometry': { 'type': 'Point', 'coordinates': x.coords },
      'properties': { ...x, 'marker-symbol': 'star' }
    }))
  }
}

export class TrackInfoTable extends React.Component {
  render() {
    let trackInfo = Object.values(this.props.trackGeoJson).map(x => x.features[0].properties)
    const filteredTrackList = _.filter(trackInfo, x => x.startTime !== undefined)
    if (_.isEmpty(filteredTrackList)) {
      return null
    }
    const totalLen = filteredTrackList.map(track => track.lengthNum).reduce((a, x) => a + x, 0)

    return (
      <React.Fragment>
        <div className="clearfix" />
        <table className="clearfix geoTable">
          <thead>
            <tr>
              <th style={{ whiteSpace: 'nowrap' }}>Datum</th>
              <th>Start</th>
              <th>Ende</th>
              <th style={{ whiteSpace: 'nowrap' }} >Dauer</th>
              <th style={{ whiteSpace: 'nowrap' }} >Fahrzeit</th>
              <th style={{ whiteSpace: 'nowrap' }} >Distanz</th>
            </tr>
          </thead>
          <tbody>
            {filteredTrackList.map((track) => {
              return (
                <tr key={track.url}>
                  <td><a href={track.link}>{track.startTimeObj.toFormat('d. LLL')}</a></td>
                  <td>{track.startName || track.title}</td>
                  <td>{track.endName || ''}</td>
                  <td>{track.duration}</td>
                  <td>{track.movingDuration}</td>
                  <td>{track.length}</td>
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr>
              <th>∑</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>{`${Math.round(totalLen)} km`}</th>
            </tr>
          </tfoot>
        </table></React.Fragment>
    )
  }
}
