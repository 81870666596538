import axios from 'axios'
import { flag } from 'country-code-emoji'
import length from '@turf/length'
import midpoint from '@turf/midpoint'
import distance from '@turf/distance'
import { point } from '@turf/helpers'
import summary from 'geojson-summary'
import geojsonExtent from '@mapbox/geojson-extent'
import localforage from 'localforage'

// import parseDMS from 'parse-dms'
import { DateTime, Interval, Duration } from 'luxon'

const _ = require('lodash')
const immediatePromise = () => new Promise((resolve) => setImmediate(resolve))
const store = localforage.createInstance({
  name: 'geonames'
})

// nette links: https://satellites.pro/Russia_map#61.898548,55.083647,14
// https://tools.wmflabs.org/geohack/geohack.php?params=60.739444444444_N_56.723611111111_E_globe:earth&language=en
// https://tools.wmflabs.org/wp-world/umkreis.php?la=en&submit=Table&lon=56.723611&lat=60.739444&rang=100&map=0&limit=500
// https://dispenser.info.tm/~dispenser/cgi-bin/locateCoord.py?dbname=enwiki&lon=56.723611&lat=60.739444&range_km=100
// https://dispenser.info.tm/~dispenser/sources/locateCoord.py

// https://github.com/mourner/rbush
// https://github.com/mapbox/supercluster

// https://www.npmjs.com/package/exif-geojson
// https://www.npmjs.com/package/simplify-geojson
// https://www.npmjs.com/package/@mapbox/geojson-tidy
// https://www.npmjs.com/package/geojson-precision
// https://www.npmjs.com/package/@mapbox/geojson-merge
// https://github.com/mapbox/geojsonhint https://github.com/zaach/jsonlint
// https://www.npmjs.com/package/@turf/flatten
// auch interessant: https://github.com/mapbox/mapbox-geostatsx

export async function withStatistics (geojson) {
  // GeoJSON um Felder erweitern, die Statistiken etc binhalten.
  // klappt nur richtig für FeatureCollectioncmit einem single linestring
  // und richtig gut nur für die, die aus GPX erstellt werden
  const neojson = { ...geojson }
  let prop = neojson.features[0].properties
  prop.bounds = geojsonExtent(geojson)
  prop.lengthNum = length(geojson, { units: 'kilometers' })
  prop.length = `${Math.round(prop.lengthNum)} km`
  prop.summary = summary(geojson).sentence
  prop.startTime = _.min(prop.coordTimes)
  prop.startTimeObj = DateTime.fromISO(prop.startTime, { setZone: true }).setLocale('de')
  prop.startCoords = geojson.features[0].geometry.coordinates[0]
  prop.endTime = _.max(prop.coordTimes)
  prop.endTimeObj = DateTime.fromISO(prop.endTime, { setZone: true }).setLocale('de')
  prop.endCoords = _.last(geojson.features[0].geometry.coordinates)

  if (prop.startTimeObj.invalid) {
    console.log('invalid date', prop.startTime, prop.startTimeObj)
  }

  let i = Interval.fromDateTimes(prop.startTimeObj, prop.endTimeObj)
  if (!i.invalid) {
    prop.durationObj = i.toDuration('hours')
    prop.duration = prop.durationObj.toFormat('h:mm') + 'h'
  }
  let {moving, stationary, stops} = await getMovingAndResting(neojson)
  prop.movingDurationObj = moving
  prop.movingDuration = prop.movingDurationObj.toFormat('h:mm') + 'h'
  prop.stationaryDurationObj = stationary
  prop.stationaryDuration = prop.stationaryDurationObj.toFormat('h:mm') + 'h'

  neojson.features.push(stops)
  return neojson
}

async function getMovingAndResting (geojson) {
  // ermittelt, moving time, stationary time und stops
  const lines = _.zip(geojson.features[0].geometry.coordinates, geojson.features[0].properties.coordTimes)
  let stationary = 0
  let moving = 0
  let stops = []
  let currentStop = []

  const sentinel = lines.length - 2
  for (var i = 0; i < sentinel; i++) {
    let startC = lines[i][0]
    let startT = DateTime.fromISO(lines[i][1], { setZone: true })
    let endC = lines[i + 1][0]
    let endT = DateTime.fromISO(lines[i + 1][1], { setZone: true })
    let d = Interval.fromDateTimes(startT, endT).toDuration('hours')
    let time = d.hours
    let dist = distance(startC, endC)
    let speed = dist / time
    if (speed < 4) { // unter 2 km/h gehen wir von stationary aus
      stationary += time
      currentStop.push({'startC': startC, 'endC': endC, 'startT': startT, 'endT': endT, 'duration': time, 'distance': distance})
    } else {
      moving += time
      if (!_.isEmpty(currentStop)) {
        stops.push(currentStop)
        currentStop = []
      }
    }
    await immediatePromise() // do not block the UI
  }

  // jetzt "richtige" Stops filtern"
  stops = _.without(stops.map(stop => {
    // stop ist eine liste
    let time = _.sum(stop.map(x => x.duration))
    if (time > 0.2) { // ab 12 Minuten gehen wir von einem "richtigen Stop" aus
      // wir reichern  des Stop noch mit ein paar Daten an
      let p = midpoint(point(stop[0].startC), point(stop[stop.length - 1].endC))
      p.properties.startT = stop[0].startT
      p.properties.endT = stop[stop.length - 1].endT
      p.properties.durationObj = Duration.fromMillis(time * 3600000)
      p.properties.duration = p.properties.durationObj.toFormat('h:mm') + 'h'
      return p
    }
    return null
  }), null)

  // TODO: stops zusammenfassen zwischen denen nur wenige Minuten liegen

  return {moving: Duration.fromMillis(moving * 3600000), stationary: Duration.fromMillis(stationary * 3600000), stops: stops}
}

// http://api.geonames.org/timezoneJSON?lat=47.01&lng=10.2&username=maxd&lang=de&date=2018-07-22
// https://github.com/mapbox/mapbox-gl-geocoder
export async function geoToPlaceName (coords, noAdmin = false) {
  // Gibt einen String zurück, der den Ort an den Koordinaten einigermassen
  // ordentlich beschreibt. Wenn noAdmin `false` ist,
  // dann wird auch der Bundesstaat oder so zugefügt.

  // geonames api hat ein API limit
  let url = `https://secure.geonames.org/findNearbyPlaceNameJSON?lat=${coords[1]}&lng=${coords[0]}&username=maxd`
  let toponym = await store.getItem(url)
  if (toponym == null) {
    let res = await axios.get(url)
    if (res.data.geonames === undefined) {
      console.log('error', res.data)
      return null
    }
    store.setItem(url, res.data.geonames[0])
    toponym = res.data.geonames[0]
  }
  if (!noAdmin && toponym.adminName1) {
    return `${toponym.toponymName} (${toponym.adminName1} ${flag(toponym.countryCode)})`
  } else {
    return `${toponym.toponymName} ${flag(toponym.countryCode)}`
  }
}

// https://api.mapbox.com/geocoding/v5/mapbox.places/60.495556%2C%2055.532778.json?access_token=pk.eyJ1IjoibWF0dGZpY2tlIiwiYSI6ImNqNnM2YmFoNzAwcTMzM214NTB1NHdwbnoifQ.Or19S7KmYPHW8YjRz82v6g&cachebuster=1551482922158&autocomplete=false&types=district%2Clocality%2Cplace%2Cneighborhood%2Cpoi&proximity=60.495556%2C55.532778&language=de
