// Topic-Seiten sind eine Kombination aus
// Tag-Übersichtsseite udn Markdown Text.
// ausserdem Ziehen sie die Tacks
// aller entsprechend getaggten Seiten udn stellen
// diese auf einer gemeinsammen KArte dar.

import React from 'react'
import { Link, graphql } from 'gatsby'

import { Layout } from '../components/layout.jsx'
import { SEO } from '../components/seo.jsx'
import { Map } from '../components/post-map.jsx'
import { PostSnippet } from '../components/post-snippet.jsx'

const _ = require('lodash')

export default class TopicTemplate extends React.Component {
  render () {
    const post = this.props.data.post
    const taggedPosts = this.props.data.taggedPosts ? this.props.data.taggedPosts.edges : []
    let locationObjs = []
    let trackObjs = post.frontmatter.tracks ? post.frontmatter.tracks.map(x => ({
      trackUrl: x && x.publicURL,
      title: post.frontmatter.title,
      link: post.fields.slug
    })) : []
    trackObjs = trackObjs.concat(
      _.filter(_.flatten(
        taggedPosts.map(
          edge =>
            edge.node.frontmatter.tracks ? edge.node.frontmatter.tracks.map(
              x => ({trackUrl: x && x.publicURL, title: edge.node.frontmatter.title, link: edge.node.fields.slug})
            ) : []
        )
      ), x => x.trackUrl !== null))

    if (post.frontmatter.locations) {
      locationObjs = post.frontmatter.locations
    }
    for (let edge of taggedPosts) {
      if (!_.isNil(edge.node.frontmatter.locations)) {
        locationObjs = locationObjs.concat(edge.node.frontmatter.locations)
      }
    }

    return (
      <Layout location={this.props.location} title={this.props.data.site.siteMetadata.title}>
        <article className='blogPostPage h-entry'>
          <SEO title={post.frontmatter.title} description={post.excerpt} />
          <header>
            <Link to={post.fields.slug}>
              <h1 className='post-title p-name'>{post.frontmatter.title}</h1>
            </Link>
          </header>

          <section className='content' dangerouslySetInnerHTML={{ __html: post.html }} />

          { !_.isEmpty(taggedPosts) &&
            <section className='toc'>
              <h2>Inhalt</h2>
              <ul>
                {taggedPosts.map(edge => (
                  <li key={edge.node.fields.slug}>
                    <Link to={edge.node.fields.slug}>
                      {edge.node.frontmatter.title} ({edge.node.frontmatter.date})
                    </Link>
                  </li>
                ))}
              </ul>
            </section>
          }

          {!(_.isEmpty(trackObjs) && _.isEmpty(locationObjs)) && <section className='geo'><Map tracks={trackObjs} locations={locationObjs} /></section>}
        </article>

        {taggedPosts.map(edge => {
          const title = edge.node.frontmatter.title || edge.node.fields.slug
          return (
            <PostSnippet key={edge.node.fields.slug} node={edge.node} title={title} />
          )
        })}

      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query TopicBySlug($slug: String!, $lastSlugPart: String!) {
    site {
      siteMetadata {
        title
      }
    }
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        tags
        tracks { publicURL }
        locations { gps, name, url }
      }
    }
    taggedPosts: allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: ASC }
      filter: { frontmatter: { tags: { in: [$lastSlugPart] }, draft: { ne: true } } }
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            # See https://www.gatsbyjs.org/docs/graphql-reference/#dates
            date(formatString: "DD. MMMM YYYY", locale: "de-DE")
            title
            tags
            draft
            tracks { publicURL }
            locations { gps, name, url }
            coverImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
              }
            }
          }
        }
      }
    }
  }
`
