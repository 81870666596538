import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'

const headerheight = '120px'
const barVbuffer = '0.5rem'
const headerHbuffer = '3.5rem'

export class Header extends React.Component {
  render () {
    const navLinks = [
      ['/', 'Home'],
      ['/topic/reisen', 'Reisen'],
      ['/topic/buecher', 'Bücher']
    ]

    return (
      <header css={css`
          flex: 1 1 100%;
          background: darkgrey;
          background-image: url(/HereBeDragons.jpg);
          background-position: center;
          background-size: cover;
        `}>
        <h1 css={css`
          min-height: ${headerheight};
          padding: 1rem ${headerHbuffer};
          width: 100%;
          margin: 0 auto;

          color: #fff;
          font-weight: 800;
          font-size: 5vw;
          letter-spacing: -1px;
          text-decoration: none;
          text-transform: uppercase;
          text-shadow: #000 0px 0px 1px;
          -webkit-font-smoothing: antialiased;
    `}>{this.props.title}</h1>
        <div
          className='nav'
          css={css`
            width: 100%;
            background: rgb(255, 208, 14);
            padding: ${barVbuffer} 0;
            &::after {
              content: "";
              display: table;
              clear: both;
            }
          `}>
          <ul
            className='nav'
            role='menu'
            css={css`
              padding: 0 calc(${headerHbuffer} - ${barVbuffer});
              margin: 0;
              display: flex;
              flexDirection: row;
              flexWrap: wrap;
            `}>
            {navLinks.map((obj) => {
              var [link, name] = obj
              return (
                <li key={link} role='menuitem' css={css`
      list-style: none;
      margin: 0;
      padding: 0.2em 0.5em;
      float: left; /* Fallback for older Browsers */
    `}>
                  <Link css={css`
      &, &:active, &.visited{
        font-size: 80%;
        font-weight: 400;
        color: #111;
        cursor: pointer;
        transition: none;
        text-decoration: none;
        text-transform: uppercase;
        padding: ${barVbuffer};
        &:hover {
          background: #949494;
          cursor: pointer;
          text-decoration: none;
        }
      }
    `} to={link} activeClassName='active'>{name}</Link>
                </li>
              )
            })}
          </ul>
        </div>
      </header>
    )
  }
}
