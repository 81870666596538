import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const _ = require('lodash')

export class PostCard extends React.Component {
  render () {
    if (!this.props.node) { return null }

    const node = this.props.node
    const tags = node.frontmatter.tags || []

    const image = ((node.frontmatter.coverImage && node.frontmatter.coverImage.childImageSharp && node.frontmatter.coverImage.childImageSharp.fluid)
      ? <Link to={node.fields.slug} rel={this.props.rel}>
        <div
          className='feature-image'
          style={{height: '300px', position: 'relative'}}
        >
          { node.frontmatter.coverImage && node.frontmatter.coverImage.childImageSharp && node.frontmatter.coverImage.childImageSharp.fluid
            ? <Img
              style={{ height: '100%', objectFit: 'cover', minWidth: '100%', minHeight: '100%' }}
              fluid={node.frontmatter.coverImage.childImageSharp.fluid} critical fadeIn={false} />
            : null
          }
        </div>
      </Link>
      : null)

    return (
      <header className={`post-header ${this.props.className}`}>
        {image}
        <section className='articlemeta'>
          <time className='dt-published' dateTime="{{date format='YYYY-MM-DD'}}">
            {node.frontmatter.date}
          </time>
          {tags.map((tag) => {
            return (
              <Link rel='tag' key={tag} to={`/tag/${tag}`}>{_.startCase(tag)}</Link>
            )
          })}
        </section>
        <Link to={node.fields.slug} rel={this.props.rel}>
          <h1 className='post-title p-name'>{node.frontmatter.title}</h1>
        </Link>

        {this.props.children}
      </header>
    )
  }
}
